import React, { useState } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";

const Career = () => {
  const [showJobs, setShowJobs] = useState(false);

  const jobOpenings = [
    {
      title: "Business Development Intern",
      experience: "NA",
      location: "Ahmedabad, Gujarat",
      Benefits: "Certificate, Stipend (6k)",
      eligibility:
        "Pursuing Graduation/PG, Communication skill, Basic computer skill, Drive for numbers",
    },
  ];

  return (
    <div className="lg:w-4/4 w-[95%] relative bg-white h-auto overflow-hidden text-black flex flex-col gap-[40px] text-center">
      <div className="w-full rounded-11xl pb-10 overflow-hidden bg-[#eaeaea]">
        <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">
          BECOME A PART OF OUR TEAM
        </h1>
        <div className="flex flex-col items-center justify-center">
          <div className="lg:w-[70%] w-[90%] flex flex-col items-center justify-center py-5 px-36 sm:px-8 gap-[10px] bg-whitesmoke rounded-11xl z-[1]">
            <p className="text-[1.5rem] sm:text-[1rem] text-center">
              We are looking for passionate people to join us on our mission to
              bring all microfinance resources and opportunities to our platform.
              We value flat hierarchy, clear communication, full ownership, and
              responsibility.
            </p>
            <p className="mt-2 text-[1.5rem] sm:text-[1rem]">
              Please mail your CV to{" "}
              <a href="mailto:hr@resolabindia.com" className="text-blue-600 underline">
                hr@resolabindia.com
              </a>
            </p>
            <Button
              className="rounded-3xs font-semibold py-[12px] mt-0 px-[30px] text-[1rem]"
              onClick={() => setShowJobs(!showJobs)}
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "12px",
                background: "#015498",
                borderRadius: "10px",
                "&:hover": { background: "#015498" },
              }}
            >
              Current Opening
            </Button>

            {showJobs && (
              <div className="job-list flex overflow-x-auto whitespace-nowrap gap-6 mt-6 mb-5 px-4">
                {jobOpenings.length > 0 ? (
                  jobOpenings.map((job, index) => (
                    <Card
                      key={index}
                      sx={{
                        width: "500px",
                        background: "#f5f5f5",
                        borderRadius: "15px",
                        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                        padding: "20px",
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontWeight: "bold", fontSize: "1.3rem", marginBottom: "12px", color: "darkblue" }}
                        >
                          {job.title}
                        </Typography>
                        

                        {/* Offers & Eligibility - Closer Together */}
                        <div className=" justify-between items-start gap-3 mt-4">
                          {/* Offers Section */}


                          {/* Eligibility Section */}
                          {job.eligibility && (
                            <div className="w-1/2">
                              <Typography variant="body2" sx={{ fontWeight: "bold",textAlign: "start", fontSize: "1.2rem",  marginBottom: "3px", color: "green" }}>
                                Eligibility:
                              </Typography>
                              <ul
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(2, minmax(200px, 1fr))", // Wider columns
                                  gap: "15px 50px", // More spacing (Vertical: 15px, Horizontal: 50px)
                                  paddingLeft: "0px",
                                  marginTop: "0px",
                                  lineHeight: "1.6", // More spacing between lines
                                }}
                              >
                                {job.eligibility.split(",").map((item, idx) => (
                                  <li
                                    key={idx}
                                    style={{
                                      textAlign: "left",
                                      fontSize: "1.2rem",
                                      listStyleType: "disc",
                                      whiteSpace: "nowrap", // Prevents wrapping issues
                                    }}
                                  >
                                    {item.trim()}
                                  </li>
                                ))}
                              </ul>


                            </div>
                            
                          )}
                          <Typography variant="body2" color="text.black" sx={{ fontSize: "1.1rem", marginBottom: "8px",textAlign: "start",}}>
                          <span style={{ fontWeight: "bold", color: "green" }}>Experience:</span> {job.experience}
                        </Typography>
                          {job.Benefits && (
                            <div style={{ flex: "1", minWidth: "250px", }}>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold", fontSize: "1.2rem", textAlign: "start",marginBottom: "5px", color: "green" }} // Offers in Green
                              >
                                Offers:
                              </Typography>
                              <ul
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                               
                                  alignItems: "start", // Center align vertically
                                  paddingLeft: "0px",
                                  marginTop: "0px",
                                  lineHeight: "1.6",
                                  listStylePosition: "inside",
                                  gap: "38px", // Maintain spacing
                                }}
                              >
                                {job.Benefits.split(",").map((benefit, idx) => (
                                  <li key={idx} style={{ textAlign: "left", fontSize: "1.1rem", listStyleType: "disc", whiteSpace: "nowrap" }}>
                                    {benefit.trim()}
                                  </li>
                                ))}
                              </ul>
                            </div>

                          )}
                        </div>
                      </CardContent>
                      <Typography variant="body2" sx={{ fontSize: "1.1rem",textAlign: "start", }}>
                        <span style={{ fontWeight: "bold", color: "green" }}>Location:</span> {job.location}
                      </Typography>
                    </Card>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    There Is No Current Opening Here
                  </Typography>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { getPartners } from "../redux/slices/providerCardSlice";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Navigation, Thumbs, Pagination, Autoplay } from "swiper/modules"; // Combined imports
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css"; // Import Swiper styles

const Footer = () => {
  const navigate = useNavigate();
  const provoiderCardsArray = useSelector((state) =>
    _.get(state, "reducer.provoiderCard", {})
  );
  const dispatch = useDispatch();
  const [itemsPerSlide, setItemsPerSlide] = useState(3);

  useEffect(() => {
    dispatch(getPartners());
    const calculateItemsPerSlide = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 500) {
        setItemsPerSlide(1);
      } else if (screenWidth < 576) {
        setItemsPerSlide(2);
      } else if (screenWidth < 768) {
        setItemsPerSlide(3);
      } else if (screenWidth < 1200) {
        setItemsPerSlide(4);
      } else {
        setItemsPerSlide(5);
      }
    };

    calculateItemsPerSlide();
    window.addEventListener("resize", calculateItemsPerSlide);
    return () => {
      window.removeEventListener("resize", calculateItemsPerSlide);
    };
  }, []);

  const onTextClick1 = (section) => {
    window.scrollTo(0, 0);
    navigate("/aboutus", { state: { section } });  // Pass section as state
  };

  const onTextClick2 = (section) => {
    window.scrollTo(0, 0);
    navigate("/product", { state: { section } });
  };

  return (
    <div className="max-w-full flex flex-col items-center justify-start pt-5 pb-[100px] pr-5 pl-[21px] box-border relative gap-[100px] leading-[normal] tracking-[normal] self-stretch mq450:gap-[25px] mq800:gap-[50px]">
      <section className="w-full lg:h-[750px] h-[1650px] absolute !m-[0] right-[0px] bottom-[0px] left-[0px] bg-darkslateblue-100" />
      <section className="flex flex-col items-center justify-start p-10 box-border relative gap-[50px] w-full lg:w-3/4 z-[1] bg-whitesmoke text-left text-45xl text-black font-dosis rounded-11xl mq800:gap-[25px] mq800:pt-16 mq800:pb-[65px] mq800:box-border">
        <div className="flex flex-row items-start justify-center box-border max-w-full">
          <h1 className="m-0 relative text-heading text-center font-bold font-inherit z-[1] mq450:text-32xl mq800:text-32xl">
            Our Valuable Users
          </h1>
        </div>
        <div className="flex flex-row flex-wrap items-center justify-center py-0 px-[1.8px] box-border gap-[49px_48px] max-w-full z-[1] mq800:gap-[24px]">
          <Swiper
            slidesPerView={itemsPerSlide}
            modules={[Pagination, Autoplay]} // Ensure Autoplay is included
            loop={true}
            // pagination={{
            //   clickable: true,
            // }}
            autoplay={{
              delay: 2000, // Adjust the delay as needed
              disableOnInteraction: false, // Allow autoplay to continue after user interaction
            }}
            grabCursor={true}
            className="preview w-full text-center"
            centeredSlides={true}
            centeredSlidesBounds={true}
          >
            {_.get(provoiderCardsArray, "partnerDetails.data", []).map(
              (slide, index) => (
                <SwiperSlide
                  key={index}
                  className="w-full flex items-center justify-center"
                >
                  <img
                    className="h-[150px] w-[150px] relative object-fit min-h-[150px] pb-10"
                    loading="lazy"
                    alt={`User ${index + 1}`}
                    src={slide}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "fallback-image-url.jpg";
                    }} // replace with a fallback image URL
                  />
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
      </section>

      <section className="w-full lg:w-3/4 flex flex-col items-start justify-start gap-[50px] max-w-full text-left text-3xl text-white font-raleway ">
        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[25px] pl-[25px] box-border max-w-full">
          <div className="flex-1 flex flex-col lg:flex-row items-center justify-center lg:items-start lg:justify-between max-w-full gap-[40px]  lg:gap-[20px] z-[1] ">
            <div className="  flex flex-col items-start justify-start gap-[16px]">
              <div className="self-stretch flex-1 rounded-xl bg-white flex flex-col items-center justify-center py-[20px] px-[10px]">
                <img
                  className="flex-1 w-[150px] object-cover"
                  loading="lazy"
                  alt=""
                  src="/image-64@2x.png"
                />
              </div>
              <b className="relative leading-[150%] text-3xl lg:text-xl lg:text-left text-center">
                <p className="m-0">Talent Market Place</p>
                <p className="m-0">For MFI</p>
              </b>
            </div>
            <div className="flex lg:flex-row flex-col lg:items-start items-center justify-start gap-[50px] lg:gap-[100px] max-w-full text-13xl ">
              <div className="flex flex-col lg:items-start items-center justify-start gap-[20px]">
                <h2 className="m-0  lg:text-inherit font-bold font-inherit inline-block ">
                  About Us
                </h2>
                <div className="self-stretch flex flex-col lg:items-start items-center justify-start gap-[10px]  lg:text-xl text-7xl ">
                  <b
                    className="cursor-pointer"
                    onClick={() => onTextClick1("company")}
                  >
                    Company
                  </b>
                  <b
                    className="cursor-pointer"
                    onClick={() => onTextClick1("team")}
                  >
                    Team
                  </b>
                  <b
                    className="cursor-pointer"
                    onClick={() => onTextClick1("career")}
                  >
                    Career
                  </b>
                  <b
                    className="cursor-pointer"
                    onClick={() => onTextClick1("partner")}
                  >
                    Partner
                  </b>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[20px]">
                <h2 className="m-0  lg:text-inherit font-bold font-inherit inline-block ">
                  Product
                </h2>
                <div className="self-stretch flex flex-col lg:items-start items-center justify-start gap-[10px]  lg:text-xl text-7xl ">
                  <b
                    className="relative leading-[150%] inline-block cursor-pointer "
                    onClick={() => onTextClick2("hiring")}
                  >
                    Hiring
                  </b>
                  <b
                    className="relative leading-[150%] inline-block cursor-pointer "
                    onClick={() => onTextClick2("assessment")}
                  >
                    Assessment
                  </b>
                  <b
                    className="relative leading-[150%] inline-block cursor-pointer "
                    onClick={() => onTextClick2("verification")}
                  >
                    Verification
                  </b>
                  <b
                    className="relative leading-[150%] inline-block  cursor-pointer "
                    onClick={() => onTextClick2("advisory")}
                  >
                    Advisory
                  </b>
                </div>
              </div>
              <div className="flex flex-col items-center lg:items-start justify-start gap-[20px]">
                <h2 className="m-0  lg:text-inherit font-bold font-inherit inline-block ">
                  Contact Us
                </h2>
                <div className="self-stretch flex flex-col lg:items-start items-center justify-start gap-[10px]  lg:text-xl text-7xl ">
                  <div className="flex lg:flex-row flex-col items-center justify-start gap-[12px] mq450:flex-wrap">
                    <div className="h-11 w-11 rounded-81xl box-border flex flex-row  lg:items-start items-center justify-start py-2.5 px-[9px] border-[1px] border-dashed border-gray-300">
                      <img
                        className="h-6 w-6 relative overflow-hidden shrink-0"
                        loading="lazy"
                        alt=""
                        src="/scaling-safari.svg"
                      />
                    </div>
                    <b className="relative leading-[150%] lg:text-left">
                      <p className="m-0">Abhishree Complex, 202,</p>
                      <p className="m-0">Opposite Star Bazaar, Satellite,</p>
                      <p className="m-0">Ahmedabad, Gujarat 380015</p>
                    </b>
                  </div>
                  <div className="flex lg:flex-row flex-col items-center justify-start gap-[12px]">
                    <div className="h-11 w-11 rounded-81xl box-border flex flex-row items-start justify-start py-2.5 px-[9px] border-[1px] border-dashed border-gray-300">
                      <img
                        className="h-6 w-6 relative overflow-hidden shrink-0"
                        loading="lazy"
                        alt=""
                        src="/frame-1.svg"
                      />
                    </div>
                    <b className="relative leading-[150%] ">07969323600</b>
                  </div>
                  <div className="flex lg:flex-row flex-col items-center justify-start gap-[12px] mq450:flex-wrap">
                    <div className="h-11 w-11 rounded-81xl box-border flex flex-row items-start justify-start py-2.5 px-[9px] border-[1px] border-dashed border-gray-300">
                      <img
                        className="h-6 w-6 relative overflow-hidden shrink-0"
                        loading="lazy"
                        alt=""
                        src="/frame-2.svg"
                      />
                    </div>
                    <b className="relative leading-[150%] whitespace-nowrap ">
                      connect@resolabindia.com
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex flex-col items-center justify-center gap-[24px] w-full z-[1] text-center text-xl">
          <div className="max-w-full  self-stretch relative box-border opacity-[0.3] border-t-[1px] border-solid border-white" />
          <div className="self-stretch flex lg:flex-row flex-col items-center justify-between max-w-full gap-[20px] ">
            <b className=" relative inline-block whitespace-pre-wrap max-w-full text-lg  mq450:leading-[24px]">{`Privacy Policy  |  Terms & Conditions  |  Cookie Policy`}</b>
            <div className="flex flex-row items-center justify-center gap-[5px] max-w-full mq450:flex-wrap">
              <Button
                className="h-16 w-16"
                endIcon={
                  <img
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    width="20px"
                    height="20px"
                    src="/frame-3.svg"
                  />
                }
                disableElevation={true}
                variant="contained"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/enliven-solutions-pvt-ltd/people/",
                    "_blank"
                  )
                }
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "50%", // Make the button circular
                  "&:hover": { background: "rgba(255, 255, 255, 0.1)" },
                  width: 64,
                  height: 64,
                }}
              />
              <Button
                className="h-16 w-16"
                endIcon={
                  <img
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    width="24px"
                    height="24px"
                    src="/frame-4.svg"
                  />
                }
                disableElevation={true}
                variant="contained"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/resolab_india/"
                  )
                }
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "60px",
                  "&:hover": { background: "rgba(255, 255, 255, 0.1)" },
                  width: 64,
                  height: 64,
                }}
              />
              <Button
                className="h-16 w-16"
                endIcon={
                  <img
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    width="24px"
                    height="24px"
                    src="/frame-5.svg"
                  />
                }
                disableElevation={true}
                variant="contained"
                onClick={() =>
                  window.open("https://www.facebook.com/resolabindia")
                }
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "60px",
                  "&:hover": { background: "rgba(255, 255, 255, 0.1)" },
                  width: 64,
                  height: 64,
                }}
              />
              <Button
                className="h-16 w-16"
                endIcon={
                  <img
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    width="24px"
                    height="24px"
                    src="/frame-6.svg"
                  />
                }
                disableElevation={true}
                variant="contained"
                onClick={() =>
                  window.open(
                    "https://x.com/RESOLABINDIA?t=iyW5v9eT36xxhuPhE8d8aw&s=08"
                  )
                }
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "60px",
                  "&:hover": { background: "rgba(255, 255, 255, 0.1)" },
                  width: 64,
                  height: 64,
                }}
              />
              <Button
                className="h-16 w-16"
                endIcon={
                  <img
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    width="24px"
                    height="24px"
                    src="/frame-7.svg"
                  />
                }
                disableElevation={true}
                variant="contained"
                onClick={() =>
                  window.open("https://www.youtube.com/@resolabindia")
                }
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "60px",
                  "&:hover": { background: "rgba(255, 255, 255, 0.1)" },
                  width: 64,
                  height: 64,
                }}
              />
            </div>
            <b className=" relative  inline-block max-w-full text-xl lg:text-lg mq450:leading-[24px]">
              All Rights Reserved by Enliven Solutions Pvt Ltd
            </b>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProviderSignup from "./ProviderSignup";
import SeekerSignup from "./SeekerSignup";
import "./SignupPage.css";

const SignupPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSeeker = location.state?.type === "seeker"; // Determine if the user is a seeker or provider

  // Navigate to home
  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <div className="signup-container">
        {/* Left Section */}
        <div className="signup-left">
          <h1>Welcome to Resolab</h1>
          <p>Talent Market Place For MFI</p>
        </div>

        {/* Right Section */}
        <div className="signup-right">
            {/* Back to Home Button */}
            {/* <button className="back-to-home" onClick={navigateToHome}>
              Back to Home
            </button> */}
            {isSeeker ? <SeekerSignup /> : <ProviderSignup />}
        </div>
    </div>
  );
};

export default SignupPage;
